import { makeStyles, Theme, Typography, TypographyProps } from '@material-ui/core';
import { ReactNode } from 'react';

interface FormRowProps {
  title: string | ReactNode;
  subtitle?: string;
  children?: ReactNode;
  hidden?: boolean;
  TitleProps?: Omit<TypographyProps<'h2'>, 'variant' | 'className'>;
  marginBottom?: string | number;
}

export const FormRow = ({ title, subtitle, children, TitleProps, hidden, marginBottom = '3rem' }: FormRowProps) => {
  const classes = useStyle({ hidden, marginBottom });
  return (
    <section className={classes.section}>
      <header className={classes.header}>
        {typeof title === 'string' ? (
          <>
            <Typography variant="h3" component="h2" gutterBottom {...TitleProps}>
              {title}
            </Typography>
            {subtitle && (
              <Typography component="h3" variant="subtitle1" color="textSecondary">
                {subtitle}
              </Typography>
            )}
          </>
        ) : (
          title
        )}
      </header>
      {children}
    </section>
  );
};

const useStyle = makeStyles<Theme, { hidden?: boolean; marginBottom: string | number }>({
  section: {
    display: ({ hidden }) => (hidden ? 'none' : 'block'),
    marginBottom: ({ marginBottom }) => marginBottom
  },
  header: {
    marginBottom: '1rem'
  }
});
