import { Grid, InputBaseComponentProps, makeStyles, Theme } from '@material-ui/core';

import { FormRow, FormTextField } from 'common/components';
import { country } from 'config';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { CreateDietFormData, createDietFormMaxFieldsMaxLength } from '../types';
import { useMobileScreenSizeDetector } from 'common/hooks';

const inputProps: InputBaseComponentProps = {
  maxLength: createDietFormMaxFieldsMaxLength.dietName
};

export const DietNameSection = () => {
  const { t } = useTranslation();
  const classes = useStyle({ hidden: country === 'germany' });
  const { setValue } = useFormContext<CreateDietFormData>();
  const isMobile = useMobileScreenSizeDetector();

  useEffect(() => {
    if (country === 'germany') {
      setValue('dietName', 'Ernährungsplan 1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12} md={6} className={classes.hidableElement}>
      <div className={classes.hint}>
        <Trans i18nKey="creatDietPage.dietNameSection.required" />
      </div>
      <FormRow title={t('creatDietPage.dietNameSection.title')} marginBottom={isMobile ? 0 : '3rem'}>
        <FormTextField
          name="dietName"
          type="text"
          placeholder={t('creatDietPage.dietNameSection.inputPlaceholder')}
          inputProps={inputProps}
        />
      </FormRow>
    </Grid>
  );
};

const useStyle = makeStyles<Theme, { hidden?: boolean }>((theme) => ({
  hidableElement: {
    display: ({ hidden }) => (hidden ? 'none' : 'block')
  },
  hint: {
    color: theme.customColors.dimGrey,
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    height: '0.875rem',
    marginBottom: '8px'
  }
}));
