import { makeStyles, Typography } from '@material-ui/core';
import { useUser } from 'features/user';
import { useTranslation } from 'react-i18next';
import { Address } from '../types';
import { formatAddress } from '../utils';

interface AddressSummaryProps {
  data: Address;
  includeDeliveryHours?: boolean;
  includeName?: boolean;
}

export const AddressSummary = ({
  data: {
    buildingNr,
    placeNr,
    street,
    postCode,
    city,
    deliveryHourTo,
    deliveryHourFrom,
    companyName,
    intercomName,
    name
  },
  includeDeliveryHours = true,
  includeName = false
}: AddressSummaryProps) => {
  const {
    profile: { firstName, lastName }
  } = useUser();
  const { t } = useTranslation();
  const classes = useStyles();
  const showDeliveryHour = includeDeliveryHours && deliveryHourTo !== null;
  return (
    <>
      {includeName && (
        <Typography className={classes.name} noWrap>
          {name}
        </Typography>
      )}
      <Typography noWrap>{`${firstName} ${lastName}`}</Typography>
      <Typography noWrap>{formatAddress({ buildingNr, placeNr, street })}</Typography>
      <Typography noWrap>{`${postCode} ${city}`}</Typography>
      <Typography noWrap>{companyName}</Typography>
      <Typography noWrap>{intercomName}</Typography>
      {showDeliveryHour && (
        <Typography noWrap>
          {deliveryHourFrom
            ? t('addresses.addressSummary.deliveryHourSinceTo', { timeFrom: deliveryHourFrom, timeTo: deliveryHourTo })
            : t('addresses.addressSummary.deliveryHourTo', { time: deliveryHourTo })}
        </Typography>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  name: {
    fontWeight: 'bold',
    marginBottom: '8px'
  }
}));
