import { components, OptionProps } from 'react-select';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core';
import { IsMultiSelect } from './types';

export const SelectOption = <OptionType,>(props: OptionProps<OptionType, IsMultiSelect>) => {
  const { children, isSelected } = props;
  const classes = useStyle();
  return (
    <components.Option {...props}>
      <div className={classes.selectOption}>
        {children}
        {isSelected && <CheckIcon fontSize="small" />}
      </div>
    </components.Option>
  );
};

const useStyle = makeStyles(() => ({
  selectOption: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'right',
    gap: '5px',
    '& > *:first-child': {
      textAlign: 'left'
    }
  }
}));
