import { makeStyles, SvgIcon, Tooltip } from '@material-ui/core';
import { FunctionComponent, ReactNode, SVGProps, useState } from 'react';

type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface InfoTooltipArrowProps {
  key: string;
  title: NonNullable<ReactNode>;
  icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  placement?: Placement;
}

export const InfoTooltipMobile = ({ key, title, icon, placement = 'bottom' }: InfoTooltipArrowProps) => {
  const classes = useStyle();
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Tooltip
      onClick={onClick}
      PopperProps={{ onClick: onClick, disablePortal: true }}
      open={isOpen}
      key={key}
      title={title}
      placement={placement}
      classes={{
        tooltip: classes.tooltip,
        popper: classes.popper
      }}
    >
      <div className={classes.iconWrapper}>
        <SvgIcon component={icon} classes={{ root: classes.icon }} />
      </div>
    </Tooltip>
  );
};

const useStyle = makeStyles((theme) => ({
  icon: {
    fontSize: '25px',
    overflow: 'visible',
    position: 'absolute',
    top: '0',
    transform: 'translateY(-75%)',
    cursor: 'pointer',
    color: theme.customColors.white
  },
  iconWrapper: {
    position: 'relative',
    display: 'inline-block',
    width: '25px'
  },
  tooltip: {
    padding: '1rem',
    background: theme.customColors.black,
    color: theme.customColors.white,
    fontSize: '0.875rem',
    maxWidth: 'none',
    fontWeight: 'normal',
    textAlign: 'center',
    boxShadow: `0px 2px 6px 0px ${theme.customColors.dimGrey} !important`
  },
  popper: {
    pointerEvents: 'all',
    cursor: 'pointer',
    width: 'calc(100% - 26px)',
    marginRight: '13px',
    marginLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      transform: 'translate3d(4px, 32px, 0px) !important'
    }
  }
}));
