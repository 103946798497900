import { useCallback, useState } from 'react';

export type VisibilityObject = {
  isVisible: boolean;
  show: VoidFunction;
  hide: VoidFunction;
  array: Visibility;
};

export type Visibility = [boolean, VoidFunction, VoidFunction];

export const useVisibilityObject = (): VisibilityObject => {
  const [isVisible, setIsVisible] = useState(false);

  const show = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hide = useCallback(() => {
    setIsVisible(false);
  }, []);

  return { isVisible, show, hide, array: [isVisible, show, hide] };
};

export const useVisibility = (): Visibility => {
  const { isVisible, show, hide } = useVisibilityObject();
  return [isVisible, show, hide];
};
